import React from 'react';
import { XCircleIcon, UserCircleIcon } from '@heroicons/react/24/solid'


const HistoryModal = (props) => {
    const openHistory = () => {
        props.viewHistory()
    }

    return (
        <div className="fixed inset-0 flex items-center justify-center z-0">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75"></div>
            <div className="bg-white dark:bg-gray-800 rounded-lg p-6 shadow-md relative text-center">
                <table>
                <thead>
                    <tr>
                        <td></td>
                        <th className='px-4 py-2'>Player</th>
                        <th className='px-4 py-2'>Dutch</th>
                        <th className='px-4 py-2'>Blitz</th>
                        <th className='px-4 py-2'>Round Score</th>
                    </tr>
                    </thead>
                    <tbody>
                    {props.rounds.map((round) => (
                        <React.Fragment key={round.roundNum}>
                            <tr>
                                <th className='border px-4 py-2' rowSpan={round.scores.length}>Round {round.roundNum}</th>
                                <td className='border px-4 py-2'>{<UserCircleIcon color={props.settings[round.scores[0].player].iconColor} className="inline h-6 w-6" />}</td>
                                <td className='border px-4 py-2'>{round.scores[0].dutchScore}</td>
                                <td className='border px-4 py-2'>{round.scores[0].blitzScore}</td>
                                <td className='border px-4 py-2'>{round.scores[0].roundScore}</td>
                            </tr>
                            {round.scores.slice(1).map((score) => (
                                <tr className='border px-4 py-2' key={score.player}>
                                    <td className='border px-4 py-2'><UserCircleIcon color={props.settings[score.player].iconColor} className="inline h-6 w-6" /></td>
                                    <td className='border px-4 py-2'>{score.dutchScore}</td>
                                    <td className='border px-4 py-2'>{score.blitzScore}</td>
                                    <td className='border px-4 py-2'>{score.roundScore}</td>
                                </tr>
                            ))}
                        </React.Fragment>
                    ))}
                    </tbody>
                </table>
                <button onClick={openHistory} className="bg-green-700 text-white hover:bg-green-400 dark:text-gray-200 font-bold py-2 px-4 mt-3 rounded inline-flex items-center">
                    <XCircleIcon className="h-6 w-6" />
                    <span>Close</span>
                </button>
            </div>
        </div>
    );
};

export default HistoryModal;