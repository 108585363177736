import React, { useState } from "react";
import { CheckCircleIcon } from '@heroicons/react/24/solid'
import './App.css';
import PlayerAdd from "./PlayerAdd";

function GameForm(props) {
    let todayDate = new Date().toLocaleDateString();
    const gameNum = props.gameNum ? props.gameNum + 1 : 1;
    let defGameName = todayDate + " - " + gameNum;
    const [gameName, setGameName] = useState(defGameName);
    const [arrPlayers, setArrPlayers] = useState([]);
    const filteredKeys = props.purple ? Object.keys(props.settings) : Object.keys(props.settings).filter((key) => {
        // Replace the condition below with your actual condition
        return props.settings[key].expansion === 'none';
      });

    const getPlayers = (players, remove) => {
        if (remove) {
            setArrPlayers(prevPlayers => prevPlayers.filter(player => player.player !== players.player))
        } else {
            setArrPlayers(prevPlayers => [...prevPlayers, players])
        }

    };

    const createGame = () => {
        const gamesArr = props.games;
        const gameObj = {};
        gameObj.gameName = gameName;
        gameObj.gameNum = gameNum;
        gameObj.players = arrPlayers;
        gameObj.gameStatus = "InProgress";
        gameObj.rounds = [];
        gamesArr.push(gameObj);
        props.addGame(gamesArr)
    };

    return (
        <div>
            <form className="w-full">
                <div className="flex flex-wrap -mx-3">
                    <div className="w-full px-3">
                        <label className="block uppercase tracking-wide text-gray-700 dark:text-gray-200 text-xs font-bold mb-2">
                            Game Name
                        </label>
                        <input
                            className="appearance-none block w-full text-gray-700 border border-gray-200 dark:text-gray-200 dark:bg-gray-600 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-gray-500"
                            type="text"
                            placeholder={defGameName}
                            value={gameName}
                            onChange={(e) => setGameName(e.target.value)}
                        />
                    </div>
                </div>
                <div className="grid grid-cols-2 md:grid-cols-4 gap-4 pb-4 max-w-[400px] md:max-w-[600px] place-items-center">
                    {filteredKeys.map((k) => (
                        <PlayerAdd key={k} imgSrc={props.settings[k].image} defName={props.settings[k].friendlyName} playerType={k} addPlayer={getPlayers} />
                    ))}
                </div>
                <button onClick={createGame} disabled={arrPlayers.length < 2} className="bg-green-700 hover:bg-green-400 text-white dark:text-gray-200 hover:bg-green-400 font-bold py-2 px-4 rounded inline-flex items-cente disabled:opacity-25">
                    <CheckCircleIcon className="h-6 w-6" />
                    <span>Save</span>
                </button>
            </form>
        </div>
    );
}

export default GameForm;
