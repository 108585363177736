import React, { useEffect, useState } from "react";
import './App.css';
import ScorecardHeader from './ScorecardHeader';
import ScorecardFooter from './ScorecardFooter';
import GameForm from './GameForm';
import GameList from './GameList';
import RoundForm from "./RoundForm";
import SettingsPanel from "./SettingsPanel";
import playerSettings from "./playerSettings";
import { PlusCircleIcon } from '@heroicons/react/24/solid'


function App() {
  const [isOpen, setIsOpen] = useState(false);
  const [games, setGames] = useState(JSON.parse(localStorage.getItem('games')) || []);
  const [progressGame, setProgressGame] = useState(
    (games && games.find((game) => game.gameStatus === "InProgress")) || {});
  const [gameInPro, setGameInPro] = useState(progressGame && Object.keys(progressGame).length !== 0 || false)
  const darkModePref = window.matchMedia('(prefers-color-scheme: dark)').matches
  const [userSettings, setUserSettings] = useState(JSON.parse(localStorage.getItem('settings')) || { darkMode: (darkModePref) ? true : false, purpleExpansion: false })
  const [isOpenPanel, setIsOpenPanel] = useState(false);

  const handleGame = (game, noGameInPro) => {
    setGames(prevGames => {
      return prevGames.map((item) => {
        return item.gameNum === game.gameNum ? { ...game } : { ...item }
      })
    })
    if (noGameInPro) {
      setGameInPro(false)
      setProgressGame({})
    }

  }

  const restartGame = (gameNumber) => {
    setGameInPro(true)
    let replayGame = games.find(obj => {
      return obj.gameNum === gameNumber
    })
    let updateStatus = {
      ...replayGame,
      gameStatus: "InProgress"
    }
    setProgressGame(updateStatus)
    setGames(prevGames => {
      return prevGames.map((item) => {
        return item.gameNum === gameNumber ? { ...updateStatus } : { ...item }
      })
    })
  }

  const handleSettings = (val, key) => {
    setUserSettings(prevVal => (
      {
        ...prevVal,
        [key]: val
      }
    ))
  }

  const openPanel = () => {
    setIsOpenPanel(prevVal => !prevVal);
  };

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    localStorage.setItem("games", JSON.stringify(games))
  }, [games]);

  useEffect(() => {
    localStorage.setItem("settings", JSON.stringify(userSettings))
    const root = window.document.documentElement;
    
    if (userSettings["darkMode"] == true) {
      const root = window.document.documentElement;
      root.classList.add("dark");
    } else {
      root.classList.remove("dark");
    }
  }, [userSettings]);



  return (
    <div className="flex flex-col min-h-screen">
      <ScorecardHeader settingsPanel={openPanel} />
      <SettingsPanel open={isOpenPanel} setIsOpen={setIsOpenPanel} settings={userSettings} setSettings={handleSettings} />
      <main className="flex-grow grid min-h-full items-start place-items-center bg-white dark:bg-gray-800 px-2 py-6 sm:py-6 lg:px-8">
        {(!isOpen && !gameInPro) &&
          <button onClick={openModal} className="bg-green-700 hover:bg-green-400 text-white dark:text-gray-200 font-bold py-2 px-4 rounded inline-flex items-center">
            <PlusCircleIcon className="h-6 w-6 m-2" />
            <span>New Game</span>
          </button>
        }
        {isOpen && <div className="items-start"><GameForm games={games} gameNum={games.length} addGame={handleGame} settings={playerSettings} purple={userSettings["purpleExpansion"]} />
          <button className="text-xs underline dark:text-gray-200" onClick={closeModal}>Cancel</button></div>
        }
        {gameInPro && <div className="items-start"><RoundForm game={progressGame} gameInPro={gameInPro} updateGame={handleGame} settings={playerSettings} /></div>}
        {(!isOpen && !gameInPro && games.length > 0) && <GameList gamesList={games} settings={playerSettings} openGame={restartGame} />}
      </main>
      <ScorecardFooter />
    </div>
  );
}

export default App;
