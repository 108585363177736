export default {
    purpleExpansion: {
        label: "Enable purple expansion players",
        type: "toggle",
        selection: false
    },
    darkMode: {
        label: "Enable dark mode",
        type: "toggle",
        selection: false
    }
  };
