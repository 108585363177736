import React from 'react';
import { HeartIcon } from '@heroicons/react/24/solid'
import './App.css';

function ScorecardFooter() {
    return (
        <footer className="p-2 w-full bg-gray-100 dark:bg-gray-700 dark:text-gray-200 text-center sm:w-auto">
            <p>Made with <HeartIcon className='inline text-red-700 dark:text-red-400 h-4 w-4' /> for B</p>
            <p className="text-sm underline"><a href="mailto:t0s8n8d7s1f4n1q1@designitsecuregroup.slack.com?subject=Dutch Blitz Scorecard App">Request Feature or Report Bug</a></p>
            <p className="text-xs mt-1">Sound Effect from <a className="underline" href="https://pixabay.com/sound-effects/?utm_source=link-attribution&utm_medium=referral&utm_campaign=music&utm_content=6313">Pixabay</a></p>
        </footer>
    );
}

export default ScorecardFooter;