import React from 'react';
import { Cog6ToothIcon } from '@heroicons/react/24/solid'
import './App.css';

function ScorecardHeader(props) {
    const viewSettings = () => {
        props.settingsPanel()
    }

    return (
        <header>
            <nav className="flex items-center justify-between flex-wrap bg-black p-6">
                <div className="flex items-center flex-shrink-0 text-white mr-6">
                    <span className="font-semibold text-xl tracking-tight">Dutch Blitz - A Vonderful Goot Scorecard</span>
                </div>
                <div className="cursor-pointer" onClick={viewSettings}>
                    <Cog6ToothIcon className='cursor-pointer w-6 h-6 text-white' />
                </div>
            </nav>
        </header>
    );
}

export default ScorecardHeader;



