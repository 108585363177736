import React from 'react';
import { XCircleIcon } from '@heroicons/react/24/solid'

const WinnerModal = (props) => {

    const gameOver = () => {
        props.completeGame()
    }

    return (
        <div className="fixed inset-0 flex items-center justify-center z-0">
            <div className={"fixed inset-0 " + props.bgColor}></div>
            <div className="place-self-start mt-20 bg-white dark:bg-gray-800 dark:text-gray-200 rounded-lg p-6 shadow-md relative text-center">
                <h2 className="text-xl font-semibold mb-4">Congratulations!</h2>
                <p>Winner:</p>
                <p className='font-semibold'>{props.winner}!</p>
                <p>Final Score:</p>
                <p className='font-semibold'>{props.score}</p>
                <img alt="Symbol of the winning player" className="w-full p-2 flex items-center justify-center" src={`images/${props.imgSrc}`} />
                <button onClick={gameOver} className="bg-green-700 hover:bg-green-400 text-white dark:text-gray-200 font-bold py-2 px-4 rounded inline-flex items-center">
                    <XCircleIcon className='h-6 w-6' />
                    <span>Close</span>
                </button>
            </div>
            <audio autoPlay>
                <source src="audio/tada-fanfare.mp3" type="audio/mpeg" />
                <p>Your browser does not support the audio element.</p>
            </audio>
        </div>
    )
};

export default WinnerModal;