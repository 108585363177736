import React from 'react';
import { UserCircleIcon } from '@heroicons/react/24/solid'
import './App.css';

function GameList(props) {

    const clearHistory = () => {
        localStorage.removeItem("games");
        window.location.reload();
    };
    
    const pickUpGame = (gameNumber) => {
        props.openGame(gameNumber)
    }
    
    return (
        <div className='text-center h-3/4 overflow-scroll'>
         <h3 className='font-bold text-lg mt-2 dark:text-gray-200'>Game History</h3><button className="text-sm underline dark:text-gray-200" onClick={clearHistory}>Clear</button>
         <ul>
         
         {props.gamesList.map((item) => (
            <li key={item.gameNum} className="border-b dark:text-gray-200">{item.gameName} | 
                {item.players.map((playerData, i) => (
                <span className="">
                    <UserCircleIcon color={props.settings[playerData.player].iconColor} className="inline h-6 w-6"/>          
                </span>
            ))}
               
            | {item.gameStatus} |
            <button className="text-sm m-1 bg-green-700 text-white hover:bg-green-400 dark:text-gray-200 py-1 px-1 rounded inline-flex items-center" onClick={(e) => pickUpGame(item.gameNum)}>{(item.gameStatus === "Paused") ? "Resume" : "Celebrate"}</button>
            </li>
        ))}
         </ul>
        </div>
    );
}

export default GameList;
  