import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import useWindowSize from 'react-use/lib/useWindowSize'
import Confetti from 'react-confetti'
import { CheckCircleIcon, PauseCircleIcon, QueueListIcon, UserCircleIcon } from '@heroicons/react/24/solid'
import './App.css';
import RoundRow from './RoundRow';
import WinnerModal from './WinnerModal';
import HistoryModal from './HistoryModal';


function RoundForm(props) {
    const fields = ["Game Score", "Player", "Dutch", "Blitz", "Round Score"];
    const players = props.game.players
    const [roundNum, setRoundNum] = useState(props.game && Object.keys(props.game) ? props.game.rounds.length + 1 : 1)
    const [allRounds, setAllRounds] = useState(props.game.rounds || []);
    const [winner, setWinner] = useState("")
    const [winnerScore, setWinnerScore] = useState(0)
    const [gameWon, setGameWon] = useState(false)
    const { width, height } = useWindowSize()
    const [allScores, setAllScores] = useState(players);
    const [showHistory, setShowHistory] = useState(false)

    const generateRound = () => {
        const playerScores = players.map(item => {
            return {
                player: item.player, blitzScore: 0, dutchScore: 0, roundScore: 0
            }
        })

        const playerRound = {
            roundNum: roundNum,
            scores: playerScores
        }

        return playerRound
    }

    const [roundScore, setRoundScore] = useState(generateRound());

    const pauseGame = () => {
        const pausedGame = {
            ...props.game,
            gameStatus: "Paused",
            rounds: allRounds
        }
        props.updateGame(pausedGame, true)
    };

    const handleRounds = (playerName, dutchScore, blitzScore, scoreNum) => {
        const updatedScores = roundScore.scores.map((score) =>
            score.player === playerName ? { ...score, blitzScore: blitzScore, dutchScore: dutchScore, roundScore: scoreNum } : score
        )

        setRoundScore({
            ...roundScore,
            scores: updatedScores,
        });
    }

    const createRound = () => {
        setAllRounds(prevRounds => [...prevRounds, roundScore])
        setRoundNum(prevVal => prevVal + 1)
    }

    const calculateScores = () => {
        let highestScore = 0

        const updatedPlayers = props.game.players.map((player) => {
            const totalScore = allRounds.reduce((acc, round) => {
                const playerScore = round.scores.find((score) => score.player === player.player);
                if (playerScore) {
                    let finalScore = acc + playerScore.roundScore; // Sum of roundScores only

                    if (finalScore >= 75 && finalScore >= highestScore) {
                        highestScore = finalScore;
                        setGameWon(true)
                        setWinner(player.player)
                        setWinnerScore(finalScore)
                    }
                    return finalScore
                }
                return acc;
            }, 0);

            return { ...player, playerScore: totalScore };
        });

        return updatedPlayers;
    }

    const endGame = () => {
        const completedGame = {
            ...props.game,
            gameStatus: "Complete",
            rounds: allRounds
        }
        props.updateGame(completedGame, true)
    }

    const handleHistory = () => {
        setShowHistory(prevVal => !prevVal)
    }

    useEffect(() => {
        const updateScores = calculateScores()
        setAllScores(updateScores)
        const addRounds = {
            ...props.game,
            players: updateScores,
            rounds: allRounds
        }
        props.updateGame(addRounds)
        setRoundScore(generateRound())

    }, [allRounds])


    return (
        <div className='m-auto p-2 md:w-3/4 dark:text-gray-200'>
            {gameWon && <div><Confetti width={width} height={height} tweenDuration={1} />
                {createPortal(
                    <WinnerModal completeGame={endGame} winner={props.settings[winner].friendlyName} score={winnerScore} imgSrc={props.settings[winner].image} bgColor={props.settings[winner].bgColor} />,
                    document.body
                )}
            </div>}
            <div>
                <h2 className='font-bold'>{props.game.gameName}</h2>
                <h4 className=''>Round {roundNum}</h4>
            </div>
            <table className='table table-fixed w-full'>
                <thead>
                    <tr>
                        {fields.map((field, fieldID) => (
                            <th key={field}>{field}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {props.game.players.map((player, rowID) => (
                        <tr key={rowID}>
                            <td className='text-center border'>{allScores[rowID].playerScore}</td>
                            <td className='text-center border'>
                                <UserCircleIcon color={props.settings[player.player].iconColor} className="inline h-6 w-6" /> {(width > 640) && props.settings[player.player].friendlyName}
                            </td>
                            <RoundRow key={roundScore.roundNum} player={player.player} roundNum={roundNum} setScore={handleRounds} />
                        </tr>
                    ))}
                </tbody>
            </table>
            <button onClick={createRound} className="bg-green-700 hover:bg-green-400 text-white dark:text-gray-200 font-bold py-2 px-4 rounded inline-flex disabled:opacity-25">
                <CheckCircleIcon className="h-6 w-6" />
                <span>Save</span>
            </button>
            <button className="text-xs m-3 text-center display-flex align-items-center justify-content-center" onClick={pauseGame}>
                <span><PauseCircleIcon className="h-4 w-4" /></span>
                <span>Pause Game</span>
            </button>
            {allRounds.length > 0 && <button className="text-xs m-3" onClick={handleHistory} >
                <QueueListIcon className="h-4 w-4" />
                <span>View Round History</span>
            </button>}
            {showHistory && <HistoryModal rounds={allRounds} viewHistory={handleHistory} settings={props.settings} />}
        </div>
    );
}

export default RoundForm;