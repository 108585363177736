import React, { useState } from "react";
import { PlusCircleIcon, CheckCircleIcon } from '@heroicons/react/24/solid'
import './App.css';


function PlayerAdd(props) {
    const [isPlayer, setIsPlayer] = useState(false);

    const addPlayer = () => {
        setIsPlayer(true);
        var playerObj = {
            "player": props.playerType,
            "playerScore": 0
        };
        props.addPlayer(playerObj, false);

    };

    const removePlayer = () => {
        setIsPlayer(false);
        var playerObj = {
            "player": props.playerType,
            "playerScore": 0
        };
        props.addPlayer(playerObj, true);

    };

    return (
        <div className="w-full relative">
            <div>
                <img alt={`Player symbol ${props.playerType}`} className="w-full opacity-50 p-2 flex items-center justify-center" src={`images/${props.imgSrc}`} />
                {!isPlayer ?
                    <div className="absolute w-full h-full top-0 bottom-0 left-0 right-0 flex items-center justify-center rounded border-gray-200 border">
                        <button onClick={addPlayer} className="w-full h-full dark:black hover:backdrop-blur text-gray-800 dark:text-gray-200 font-bold py-2 px-4 rounded inline-flex items-center">
                        <PlusCircleIcon className="h-6 w-6 m-1"/>
                            <span>Add Player</span></button>
                    </div>
                    :
                    <div onClick={(e) => removePlayer(props.playerType)} className="absolute w-full h-full top-0 bottom-0 left-0 right-0 flex items-center justify-center rounded border-gray-200 border">
                        <CheckCircleIcon className="h-8 w-8 text-green-700 dark:text-green-400" />
                    </div>
                }
            </div>
        </div>)

}

export default PlayerAdd;
















