import React, { useEffect, useState } from 'react';

function RoundRow (props) {

    const [blitzScore, setBlitzScore] = useState(0);
    const [dutchScore, setDutchScore] = useState(0);


    const calculatePlayerScore = (e) => {
        const {name,value} = e.target
        if (isNaN.value) {
            return
        }
        if (name === "dutch") {
            setDutchScore(parseInt(value));
        }
        if (name === "blitz") {
            setBlitzScore(parseInt(value));
        }        
    };

    const finalScore = parseInt(-dutchScore) + parseInt(blitzScore*2)

    useEffect(() => {
        props.setScore(props.player, dutchScore, blitzScore, finalScore)
    }, [dutchScore, blitzScore, finalScore])

    return (
        <>
        <td className='text-center border'><span className='inline p-2'>-</span><input type="number" inputmode="numeric" pattern="[0-9]*" min="0" step="1" value={dutchScore} onChange={(e) => calculatePlayerScore(e)} className="dark:text-gray-200 dark:bg-gray-600 w-11/12 sm:w-1/2 m-1 align-middle text-center appearance-none text-gray-700 border border-gray-200 rounded p-2 leading-tight focus:outline-none focus:border-gray-500" name="dutch" /></td>
        <td className='text-center border'><span className='inline p-2'>x2</span><input type="number" inputmode="numeric" pattern="[0-9]*" min="0" step="1" value={blitzScore} onChange={(e) => calculatePlayerScore(e)} className="dark:text-gray-200 dark:bg-gray-600 w-11/12 sm:w-1/2 m-1 align-middle text-center appearance-none  text-gray-700 border border-gray-200 rounded p-2 leading-tight focus:outline-none focus:border-gray-500" name="blitz" /></td>
        <td className='text-center border'><input value={(isNaN(finalScore)) ? 0 : finalScore} className='text-gray-700 dark:text-gray-200 dark:bg-gray-800 w-11/12 sm:w-1/2 text-center' readOnly disabled /></td>
        </>
    
    )
}

export default RoundRow;