import React, { Fragment } from "react";
import { Dialog, Transition, Switch } from '@headlessui/react'
import { XCircleIcon } from "@heroicons/react/24/solid";
import userSettings from "./userSettings";
import './App.css';
import packageInfo from '../package.json'


function SettingsPanel(props) {

    const updateSettings = (e, key) => {
        props.setSettings(e, key);
    }

    return (
        <Transition.Root show={props.open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={() => props.setIsOpen(false)}>
                {/* Backdrop overlay */}
                <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>
                <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                    {/* Sliding Panel */}
                    <Transition.Child
                        as={Fragment}
                        enter="transform transition ease-in-out duration-500 sm:duration-700"
                        enterFrom="translate-x-full"
                        enterTo="translate-x-0"
                        leave="transform transition ease-in-out duration-500 sm:duration-700"
                        leaveFrom="translate-x-0"
                        leaveTo="translate-x-full"
                    >
                        <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                            <div className="flex h-full flex-col overflow-y-scroll bg-white dark:bg-gray-800 dark:text-gray-200 py-6 shadow-xl">
                            <div className="ml-3 flex h-7 items-start justify-between">
                            <Dialog.Title className="ml-3 font-bold">Settings</Dialog.Title>
                                    <button
                                        type="button"
                                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 dark:bg-gray-800 dark:text-gray-400"
                                        onClick={() => props.setIsOpen(false)}
                                    >
                                        <span className="sr-only">Close panel</span>
                                        <XCircleIcon className="h-6 w-6 mr-3" aria-hidden="true" />
                                    </button>
                                </div>
                                <div className="relative mt-6 flex-1 px-4 sm:px-6">
                                    {Object.keys(userSettings).map((key) => (
                                    <div key={key}><p className="my-3">{userSettings[key].label}</p>
                                    <Switch
                                            checked={props.settings[key]}
                                            onChange={(e) => updateSettings(e, key)}
                                            className={`${props.settings[key] ? 'bg-green-600' : 'bg-gray-200'} relative inline-flex h-6 w-11 items-center rounded-full`}
                                        >
                                            <span className="sr-only">{userSettings[key].label}</span>
                                            <span
                                                className={`${props.settings[key] ? 'translate-x-6' : 'translate-x-1'} inline-block h-4 w-4 transform rounded-full bg-white transition`} />
                                        </Switch>
                                        <hr className="m-3" /></div>
                                ))}
                                </div>
                                <div className="text-xs text-gray-300 ml-6">{packageInfo.version}</div>
                            </div>
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>

    );
}

export default SettingsPanel;
















