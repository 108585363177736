export default
  {
    bluePlow: {
      emoji: '🔵',
      friendlyName: 'Blue Plow',
      image: 'blue_plow.png',
      expansion: 'none',
      bgColor: 'bg-blue-200',
      iconColor: '#60a5fa'
    },
    yellowPail: {
      emoji: '🟡',
      friendlyName: 'Yellow Pail',
      image: 'yellow_pail.png',
      expansion: 'none',
      bgColor: 'bg-yellow-200',
      iconColor: '#FACC15'
    },
    redCarriage: {
      emoji: '🔴',
      friendlyName: 'Red Carriage',
      image: 'red_carriage.png',
      expansion: 'none',
      bgColor: 'bg-red-200',
      iconColor: '#f87171'
    },
    greenWell: {
      emoji: '🟢',
      friendlyName: 'Green Well',
      image: 'green_well.png',
      expansion: 'none',
      bgColor: 'bg-green-200',
      iconColor: '#4ade80'
    },
    purpleChurner: {
      emoji: '🟣',
      friendlyName: 'Purple Churner',
      image: 'purple_churner.png',
      expansion: 'purple',
      bgColor: 'bg-purple-200',
      iconColor: '#c084fc'
    },
    pinkWateringCan: {
      emoji: '🟣',
      friendlyName: 'Pink Watering Can',
      image: 'pink_watering_can.png',
      expansion: 'purple',
      bgColor: 'bg-pink-200',
      iconColor: '#f472b6'
    },
    orangeHat: {
      emoji: '🟠',
      friendlyName: 'Orange Hat',
      image: 'orange_hat.png',
      expansion: 'purple',
      bgColor: 'bg-orange-200',
      iconColor: '#FB923C'
    },
    blackWheelbarrow: {
      emoji: '⚫',
      friendlyName: 'Black Wheelbarrow',
      image: 'black_wheelbarrow.png',
      expansion: 'purple',
      bgColor: 'bg-gray-200',
      iconColor: '#d1d5db'
    }
  };
